<template>
  <div>
    <h1>ご注文者様情報の編集</h1>

    <login v-if="!logined" v-model="logined" />
    
    <div v-if="logined">
      <table
        width="100%"
        border="0"
        cellpadding="0"
        cellspacing="1"
        class="formBox"
        :class="{ fixed: myDataLoaded }"
      >
        <tbody>
          <tr>
            <th height="45" colspan="2" class="formListTh">
              ご依頼主(ご注文者様)情報
            </th>
          </tr>

          <tr class="table1" v-if="!logined">
            <td valign="top" class="formListTd" style="width: 11em">
              自動入力
            </td>
            <td class="formListTdw">
              <span v-if="logined" @click="bn_getMyData"
                ><button>会員登録済みの情報を読み込む</button>
              </span>
              <span v-else
                >※ログインすると、面倒な入力を省略することができます。</span
              >
            </td>
          </tr>

          <tr class="table1">
            <td valign="top" class="formListTd" style="width: 11em">
              ご注文者様名<span class="nesry">必須</span>
            </td>
            <td class="formListTdw">
              <span class="em2">姓</span>
              <input
                v-model="tmp_record.fields.sei.value"
                class="fm_100"
                name="user_name1"
                type="text"
                id="user_name1"
                value=""
              />

              <inlineError name="sei" :errors="errors_global" />

              <span class="em2">名</span>
              <input
                v-model="tmp_record.fields.mei.value"
                class="fm_100"
                name="user_name2"
                type="text"
                id="user_name2"
                value=""
              />

              <inlineError name="mei" :errors="errors_global" />
              <div class="cartinfosample">
                例：<span>黒須</span> ／ <span>三太</span>
              </div>
            </td>
          </tr>
          <tr class="table1">
            <td class="formListTd">
              ご注文者様名ふりがな<span class="nesry">必須</span>
            </td>
            <td class="formListTdw">
              <div class="cartselinfo">
                ※ひらがな、またはカタカナで入力してください。
              </div>
              <span class="em2">せい</span>
              <input
                class="fm_100"
                v-model="tmp_record.fields.sei_kana.value"
                name="user_name_kana1"
                type="text"
                id="user_name_kana1"
                value=""
              />
              <inlineError name="sei_kana" :errors="errors_global" />
              <span class="em2">めい</span>
              <input
                class="fm_100"
                v-model="tmp_record.fields.mei_kana.value"
                name="user_name_kana2"
                type="text"
                id="user_name_kana2"
                value=""
              />
              <inlineError name="mei_kana" :errors="errors_global" />

              <div class="cartinfosample">
                例：<span>くろす</span> ／ <span>さんた</span>
              </div>
            </td>
          </tr>

          <tr class="table1">
            <td class="formListTd">
              <span class="tdmenu">ご注文者様</span>住所
            </td>
            <td class="formListTdw">
              <table border="0" class="formBox">
                <tbody>
                  <tr>
                    <td width="29%" class="formListTd">
                      郵便番号<span class="nesry">必須</span>
                    </td>
                    <td width="71%" class="formListTdw">
                      <div class="cartselinfo">
                        ※住所が未入力の場合、正しく郵便番号を入力すると自動的に住所を入力します。
                      </div>
                      <input
                       @change.stop="zipcode_get"
                        v-model="tmp_record.fields.zip.value"
                        class="fm_150 zipsearch"
                        name="user_zip"
                        type="text"
                        id="user_zip"
                        value=""
                      />
                      
          <div v-if="zipresults.length>1">
            <select name="zipresults" id="" v-model="zipselected" @change="zipcode_select">
              <option value="" disabled selected>以下から選択してください</option>
              <option :value="v" v-for="v,key in zipresults" :key="key">{{v.ja.prefecture}}{{v.ja.address1}}</option>
            </select>
          </div >

          
                      <inlineError name="zip" :errors="errors_global" />

                      <div class="cartinfosample">
                        例：<span>123-0001</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="formListTd">
                      都道府県<span class="nesry">必須</span>
                    </td>
                    <td class="formListTdw">
                      <select
                        name="user_ken"
                        id="user_ken"
                        class="zipsearch_ken"
                        v-model="tmp_record.fields.state.value"
                      >
                        <option value="">都道府県</option>
                        <option value="北海道">北海道</option>
                        <option value="青森県">青森県</option>
                        <option value="岩手県">岩手県</option>
                        <option value="宮城県">宮城県</option>
                        <option value="秋田県">秋田県</option>
                        <option value="山形県">山形県</option>
                        <option value="福島県">福島県</option>
                        <option value="茨城県">茨城県</option>
                        <option value="栃木県">栃木県</option>
                        <option value="群馬県">群馬県</option>
                        <option value="埼玉県">埼玉県</option>
                        <option value="千葉県">千葉県</option>
                        <option value="東京都">東京都</option>
                        <option value="神奈川県">神奈川県</option>
                        <option value="新潟県">新潟県</option>
                        <option value="富山県">富山県</option>
                        <option value="石川県">石川県</option>
                        <option value="福井県">福井県</option>
                        <option value="山梨県">山梨県</option>
                        <option value="長野県">長野県</option>
                        <option value="岐阜県">岐阜県</option>
                        <option value="静岡県">静岡県</option>
                        <option value="愛知県">愛知県</option>
                        <option value="三重県">三重県</option>
                        <option value="滋賀県">滋賀県</option>
                        <option value="京都府">京都府</option>
                        <option value="大阪府">大阪府</option>
                        <option value="兵庫県">兵庫県</option>
                        <option value="奈良県">奈良県</option>
                        <option value="和歌山県">和歌山県</option>
                        <option value="鳥取県">鳥取県</option>
                        <option value="島根県">島根県</option>
                        <option value="岡山県">岡山県</option>
                        <option value="広島県">広島県</option>
                        <option value="山口県">山口県</option>
                        <option value="徳島県">徳島県</option>
                        <option value="香川県">香川県</option>
                        <option value="愛媛県">愛媛県</option>
                        <option value="高知県">高知県</option>
                        <option value="福岡県">福岡県</option>
                        <option value="佐賀県">佐賀県</option>
                        <option value="長崎県">長崎県</option>
                        <option value="熊本県">熊本県</option>
                        <option value="大分県">大分県</option>
                        <option value="宮崎県">宮崎県</option>
                        <option value="鹿児島県">鹿児島県</option>
                        <option value="沖縄県">沖縄県</option>
                      </select>
                      <inlineError name="state" :errors="errors_global" />

                      <div class="cartinfosample">例：<span>栃木県</span></div>
                    </td>
                  </tr>
                  <tr>
                    <td class="formListTd">
                      市区町村<span class="nesry">必須</span>
                    </td>
                    <td class="formListTdw">
                      <input
                        v-model="tmp_record.fields.city.value"
                        class="fm_150 zipsearch_shi"
                        id="user_shi"
                        name="user_shi"
                        type="text"
                        value=""
                      />
                      <inlineError name="city" :errors="errors_global" />

                      <div class="cartinfosample">
                        例：<span>那須郡那須町</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="formListTd">
                      地域・番地<span class="nesry">必須</span>
                    </td>
                    <td class="formListTdw">
                      <input
                        v-model="tmp_record.fields.street.value"
                        class="fm_250 zipsearch_machi"
                        id="user_addr01"
                        name="user_addr01"
                        type="text"
                        value=""
                      />
                      <inlineError name="street" :errors="errors_global" />

                      <div class="cartinfosample">
                        例：<span>高久乙3584-4</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="formListTd">アパート・マンション・建物名</td>
                    <td class="formListTdw">
                      <div class="cartselinfo">
                        ※建物名、号室まで正確に入力してください
                      </div>
                      <input
                        v-model="tmp_record.fields.building.value"
                        class="fm_250"
                        id="user_addr02"
                        name="user_addr02"
                        type="text"
                        value=""
                      />
                      <inlineError name="building" :errors="errors_global" />

                      <div class="cartinfosample">
                        例：<span>クリスマスマンションＡ棟５００号室</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="formListTd">様方・会社名</td>
                    <td class="formListTdw">
                      <input
                        v-model="tmp_record.fields.other.value"
                        class="fm_250"
                        id="user_addr03"
                        name="user_addr03"
                        type="text"
                        value=""
                      />
                      <inlineError name="other" :errors="errors_global" />

                      <div class="cartinfosample">
                        例：<span>黒須様方</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr class="table1">
            <td class="formListTd">
              ご注文者様電話番号<span class="nesry">必須</span>
            </td>
            <td class="formListTdw">
              <div class="cartselinfo">
                ※数字とハイフンで入力してください。<br />※市外局番から入力してください
              </div>
              <input
                class="fm_150"
                name="user_tel"
                type="text"
                v-model="tmp_record.fields.tel.value"
              />
              <inlineError name="tel" :errors="errors_global" />

              <div class="cartinfosample">例：<span>0287-99-9999</span></div>
            </td>
          </tr>
          <!--
        <tr class="table1">
          <td class="formListTd">
            <span class="tdmenu">ご注文者様</span>携帯電話番号
          </td>
          <td class="formListTdw">
            <div class="cartselinfo">
              ※携帯番号しかお持ちでない方は、ご注文者様電話番号欄へ入力してください。<br />※数字とハイフンで入力してください。
            </div>
            <input class="fm_150" name="user_mtel" type="text" value="" />

            <div class="cartinfosample">例：<span>080-9999-9999</span></div>
          </td>
        </tr>-->
          <tr class="table1">
            <td height="74" class="formListTd">
              ご注文者様メールアドレス<span class="nesry">必須</span>
            </td>
            <td class="formListTdw">
              <div class="cartselinfo">
                ※間違いのないよう、正確に入力してください。<br />
                ※携帯アドレスをご使用の場合、@santaclaus-embassy.comからのメールを受信できるように設定してください。
              </div>
              <input
                class="fm_150"
                name="user_email"
                type="text"
                v-model="tmp_record.fields.email.value"
              />
              <inlineError name="email" :errors="errors_global" />

              <div class="cartinfosample">
                例：<span>mail@santaclaus-embassy.com</span>
              </div>
            </td>
          </tr>
          <tr class="table1" v-if="!myDataLoaded">
            <td height="74" class="formListTd">
              ご注文者様メールアドレス<br />
              再入力<span class="nesry">必須</span>
            </td>
            <td class="formListTdw">
              <div class="cartselinfo">
                ※コピーせずにもう一度入力してください。
              </div>

              <div class="cartinfosample">
                ▼ご確認のためもう一度メールアドレスを入力してください。
              </div>

              <input
                class="fm_150"
                name="user_email2"
                type="text"
                v-model="tmp_record.fields.email_c.value"
              />
              <inlineError name="email_c" :errors="errors_global" />

              <div class="cartinfosample">
                例：<span>mail@santaclaus-embassy.com</span>
              </div>
            </td>
          </tr>
          <tr class="table1 memberbox" v-if="!logined">
            <td valign="top" class="formListTd" style="width: 11em">
              会員登録
            </td>
            <td class="formListTdw">
              <input
                type="checkbox"
                name="user_submit"
                value="on"
                checked=""
              />
              会員登録をして、次回から住所などを自動入力する

              <div class="cartinfosample">
                今回のご注文完了と同時に会員登録をして、次回からの入力を省略することができます。毎年忘れずにお申し込みできるようにお知らせも配信されます。
              </div>
            </td>
          </tr>

          <tr class="table1">
            <td valign="top" class="formListTd" style="width: 11em">
              変更するパスワード
            </td>
            <td class="formListTdw">
              <input
                type="password"
                name="user_pass"
                v-model="tmp_record.fields.userpass.value"
              />
              <inlineError name="userpass" :errors="errors_global" />
              <div class="cartinfosample">
                パスワードを変更する場合は、半角英数字6～50文字でお願いします。（記号可）
              </div>
              <input
                type="password"
                name="user_pass_c"
                v-model="tmp_record.fields.userpass_c.value"
              />
              <inlineError name="userpass_c" :errors="errors_global" />
              <div class="cartinfosample">
                確認のためにもう一度入力してください。
              </div>
            </td>
          </tr>
          <tr class="table1 mailmagbox">
            <td class="formListTd">メルマガ登録</td>
            <td class="formListTdw">
              <!--<div class="cartselinfo">※サンタクロース大使館から情報メールをお送りしてもよろしい場合はチェックを入れてください</div>-->
              <input
                name="user_mailmaga"
                id="user_mailmaga"
                type="checkbox"
                value="on"
              v-model="tmp_record.fields.mailmag.value"
                checked="checked"
              />
              <label for="user_mailmaga"
                >メルマガ登録する
                (おすすめ！毎年忘れずにお申し込みできるようにお知らせ)</label
              ><br />
              <!--<div class="cartinfosample">※年に数回程度の発行です。新しい商品などをお知らせしますので、ぜひご登録ください。</div>-->
            </td>
          </tr>

          <tr class="table1" v-if="!logined">
            <td class="formListTd">
              規約への同意<span class="nesry">必須</span>
            </td>
            <td class="formListTdw">
              <div class="cartselinfo">
                ※同意を頂けましたらチェックを入れてください。
              </div>

              <input
                name="agreement"
                type="checkbox" class="zoomcheck"
                value="on"
                v-model="tmp_record.fields.policy.value"
              />
               <router-link @click.native="scrollToTop" to="/informations/terms">ご利用規約</router-link>
           ,
           <router-link @click.native="scrollToTop" to="/informations/privacy">プライバシーポリシー</router-link>
に同意する
              <inlineError name="policy" :errors="errors_global" />
            </td>
          </tr>
        </tbody>
      </table>

      <a class="histryback" v-if="$route.query.rd=='back'" @click="$router.back()">変更をキャンセル</a>
      

      <button @click="submit">変更内容を保存</button>
    </div>

    <dialogalert
      v-model="visible_error"
      :title="error_title"
      :info="error_info"
      button_ok="OK"
    />
    <dialogalert
      v-model="visible_success"
      :title="success_title"
      :info="success_info"
      :ok_fn="ok_fn"
      button_ok="OK"
    />


    
    <partmenu v-if="$route.query.rd!='back'" />

    
  </div>
</template>

<script>
import inlineError from "@/components/inlinError.vue";
import partmenu from "@/components/part_menu.vue"; // @ is an alias to /src
import login from "@/components/login";

export default {
  components: {
    partmenu,
    inlineError,
    login,
  },
  data: function () {
    return {
      visible_success:false,
      success_title:"",
      success_info:"",
      ok_fn:function(){},
      tmp_record: {
        fields: {
          sei: { value: "", tag: ["join"] },
          mei: { value: "", tag: ["join"] },
          sei_kana: { value: "", tag: ["join"] },
          mei_kana: { value: "", tag: ["join"] },
          email: { value: "", tag: ["join"] },
          userpass: { value: "", tag: ["join"] },
          role: { value: "", tag: ["join"] },
          zip: { value: "", tag: ["join"] },
          state: { value: "", tag: ["join"] },
          city: { value: "", tag: ["join"] },
          street: { value: "", tag: ["join"] },
          building: { value: "", tag: ["join"] },
          other: { value: "", tag: ["join"] },
          address: { value: "", tag: ["join"] },
          tel: { value: "", tag: ["join"] },
          
        email_c:{value:""},
        userpass_c:{value:""},
          mailmag: { value: "on" },

        },
      },
      myDataLoaded: false,
      zipresults:[],
      zipselected:{}
    };
  },
  watch: {
    logined: function (e) {
      console.log(e, this.logined, "ログイン状態変更");
      if (!e) {
        this.myDataLoaded = false;
      } else {
        this.bn_getMyData();
      }
    },
  },
  computed: {
    url_back:function(){
      if(this.$route.query.rd=='back') return "/item/"+this.$route.query.item+"/sender";
      return "/mypage/";
    }
  },
  created: function () {
   this.$parent.middle = 2; 
      this.loaded_countmax=1;
      this.loaded_countup();
      if(this.logined&&!this.myDataLoaded){
        this.bn_getMyData();
      }
  },
  methods: {
    zipcode_get:function(){
    
      this.fetch("zipcode",{ zipcode: this.tmp_record.fields.zip.value }).then((result) =>{
        
        if(result){
        this.$set(this.tmp_record.fields.state,"value",result.data[0].ja.prefecture);
        this.$set(this.tmp_record.fields.city,"value",result.data[0].ja.address1);
        this.$set(this.tmp_record.fields.street,"value",result.data[0].ja.address2);
        }
      });
      
    },
    zipcode_select:function(){
      console.log(this.zipselected)
    },
    bn_getMyData: function () {
      this.getMyData().then((data) => {
        for (let i in data.mydata) {
          console.log(i);
          for (let r in this.tmp_record.fields) {
            if (r == i) {
              this.$set(
                this.tmp_record.fields[r],
                "value",
                data.mydata[i].value
              );
            }
          }
        }
        this.myDataLoaded = true;
        this.$nextTick(() => {
          //$('.formBox.fixed').find("input").attr('readonly',true);
        });
      });
    },
    submit: function () {
        var data = {fields: this.tmp_record.fields,mode:"update"};
        this.fetch("myuser", data).then(data => {
          this.errors_global = {};
          if (typeof data.errors != "undefined") {
            this.errors_global = data.errors;
            console.log(data.errors);
            this.error_info = "不備の項目があります。<br>";
            for (let i = 0; i < data.errors.length; i++) {
                this.error_info += "・ " + data.errors[i].msg + "<br>";
                this.visible_error = true;
            }
          }else{
      this.visible_success=true;
      this.success_title="変更を完了しました",
      this.success_info="ご依頼主(ご注文者様)情報の変更を完了しました。"
            if(this.$route.query.rd=='back'){
              this.ok_fn=function(){
              this.$router.back();
              }.bind(this)
            }
          }
        })
        
/*
      //ログイン済みの場合はトークン、非会員の場合はuserを「-1」にする。
      var token = this.get_localStorage("token");
      if (!token) this.tmp_record.user = "-1";
      this.fetch("order_submit", {
        token: token,
        record: this.tmp_record,
        mode: "check",
      }).then((data) => {
        console.log(data);
        this.errors_global = {};
        if (typeof data.error != "undefined") {
          this.errors_global = data.error;
          this.error_info = "不備の項目があります。<br>";
          for (let i = 0; i < data.error.length; i++) {
              this.error_info += "・ " + data.error[i].msg + "<br>";
              this.visible_error = true;
          }
        }
      });*/
    },
  },
};
</script>


<style scoped lang="scss">
.histryback{
 cursor: pointer;
 margin-right: 1em;
 &:hover{
   font-weight: bold;
 } 
}
h1{
  margin-top: 1em;
}
table.formBox {
  max-width: 900px;
  margin: 0 auto;
  text-align: left;
  th,
  td {
    padding: 0.4em;
    border-bottom: solid 1px #ccc;
  }
  > tbody {
    > tr {
      > td:nth-child(1) {
        background: rgb(241, 241, 241);
      }
      border-bottom: solid 1px #ccc;
    }
  }
  .cartinfosample {
    font-size: 75%;
    margin-top: 5px;
    line-height: 1.2;
  }
  .cartselinfo {
    background-color: #fff2c8;
    padding: 10px;
    float: right;
    width: 190px;
    font-size: 75%;
    color: #282828;
    line-height: 1.5;
  }
  .cartinfosample span {
    font-weight: bold;
    color: #66b158;
  }
  element.style {
  }
  .nesry {
    font-size: 70%;
    float: right;
    padding-top: 2px;
    padding-right: 7px;
    padding-bottom: 2px;
    padding-left: 7px;
    color: #f51212;
    margin-right: 5px;
    border: 1px solid #f51212;
  }
}
</style>
